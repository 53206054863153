import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css'
import './style.sass'


function App() {
    const [data, setData] = useState();
    const [width, setWidth] = useState(window.innerWidth);
    const [mobileMenu, setMobileMenu] = useState(false);

    const updateWidthAndHeight = () => {
        setWidth(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", updateWidthAndHeight);
        return () => window.removeEventListener("resize", updateWidthAndHeight);
    });

    useEffect(() => {
        fetch('/data.json')
            .then(response => response.json())
            .then(request => setData(request))
    }, [])
    return (
        <div className="main">
            <div className='container'>
                <div className="header">
                    <img alt="Brand" src={data?.header.icon}/>
                    {
                        width >= 450 ? <ul className="nav navbar-nav  navbar-right">
                            <li className="active"><a key={data?.header.menu.one} href="#">{data?.header.menu.one}</a></li>
                            <li><a key={data?.header.menu.two} href="#one">{data?.header.menu.two}</a></li>
                            <li><a key={data?.header.menu.three} href="#two">{data?.header.menu.three}</a></li>
                            <li><a key={data?.header.menu.four} href="#three">{data?.header.menu.four}</a></li>
                        </ul> : mobileMenu ? <button onClick={() => setMobileMenu(false)}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.807617" y="17.7782" width="24" height="2" rx="1" transform="rotate(-45 0.807617 17.7782)" fill="white"/>
                                <rect x="2.41406" y="1" width="24" height="2" rx="1" transform="rotate(45 2.41406 1)" fill="white"/>
                            </svg>
                        </button> : <button onClick={() => setMobileMenu(true)}>
                            <svg width="24" height="16" viewBox="0 0 24 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="24" height="2" rx="1" fill="white"/>
                                <rect y="7" width="24" height="2" rx="1" fill="white"/>
                                <rect y="14" width="24" height="2" rx="1" fill="white"/>
                            </svg>
                        </button>
                    }
                </div>
                {
                    mobileMenu && <ul className="header nav navbar-nav  navbar-right">
                        <li className="active"><a key={data?.header.menu.one} href="#">{data?.header.menu.one}</a></li>
                        <li><a key={data?.header.menu.two} href="#one">{data?.header.menu.two}</a></li>
                        <li><a key={data?.header.menu.three} href="#two">{data?.header.menu.three}</a></li>
                        <li><a key={data?.header.menu.four} href="#three">{data?.header.menu.four}</a></li>
                    </ul>
                }
            </div>
            <div className="container-fluid firts_screen">
                <div className="container">
                    <div className="leftBlock">
                        <div key={data?.first_screen.title} className='welcome'>{data?.first_screen.title}</div>
                        <div key={data?.first_screen.name} className='trading'>{data?.first_screen.name}</div>
                        <div key={data?.first_screen.name2} className='welcome2'>{data?.first_screen.name2}</div>
                        <div key={data?.first_screen.description} className='description'>{data?.first_screen.description}</div>
                        <a href="#one" key={data?.first_screen.button}>{data?.first_screen.button}</a>
                    </div>
                </div>
            </div>
            <div id="one" className="second_screen">
                <div className="container">
                    <div className="rightBlock">
                        <div key={data?.second_screen.rightBlock.title} className="title">{data?.second_screen.rightBlock.title}</div>
                        <div key={data?.second_screen.rightBlock.info} className="info">{data?.second_screen.rightBlock.info}</div>
                        <div key={data?.second_screen.rightBlock.description} className="description">{data?.second_screen.rightBlock.description}</div>
                    </div>
                    <div className="leftBlock">
                        {
                            data?.second_screen.leftBlock.map(
                                (item) => <div key={item.title} className="item">
                                    <img src={item.icon} alt={item.title}/>
                                    <div className="title">{item.title}</div>
                                    <div className="description">{item.description}</div>
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <div id="two" className="container third_screen">
                <div className="rightBlock">
                    <img src={data?.three_screen.rightBlock.img} alt=""/>
                </div>
                <div className="leftBlock">
                    <div key={data?.three_screen.leftBlock.title} className="title">{data?.three_screen.leftBlock.title}</div>
                    <div key={data?.three_screen.leftBlock.info} className="info">{data?.three_screen.leftBlock.info1}<br/>{data?.three_screen.leftBlock.info2}</div>
                    <div key={data?.three_screen.leftBlock.description} className="description">
                        {data?.three_screen.leftBlock.description1}<br/><br/>
                        {data?.three_screen.leftBlock.description2}<br/><br/>
                        {data?.three_screen.leftBlock.description3}<br/><br/>
                    </div>
                </div>
            </div>
            <div id="three" className="contact">
                <div className="container">
                    <div className="leftBlock">
                        <div className="title"><strong>{data?.contact.leftBlock.title}</strong></div>
                        <div className="address text">You can write or call us to use<br/>our contacts, our managers will<br/>answer you soon</div>
                        <div className="director">Epsilon: <strong>{data?.contact.leftBlock.director}</strong></div>
                    </div>
                    <div className="leftBlock rightBlock">
                        <div className="address">
                            <img src={data?.contact.leftBlock.address.icon} alt=""/>
                            <div className="text">{data?.contact.leftBlock.address.text}</div>
                        </div>
                        <div className="address">
                            <img src={data?.contact.leftBlock.phone.icon} alt=""/>
                            <div className="text">{data?.contact.leftBlock.phone.text}</div>
                        </div>
                        <div className="address">
                            <img src={data?.contact.leftBlock.email.icon} alt=""/>
                            <div className="text">{data?.contact.leftBlock.email.text}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className="header">
                    <img alt="Brand" src={data?.header.icon}/>
                    <ul className="nav navbar-nav  navbar-right">
                        <li className="active"><a key={data?.header.menu.one} href="#">{data?.header.menu.one}</a></li>
                        <li><a key={data?.header.menu.two} href="#one">{data?.header.menu.two}</a></li>
                        <li><a key={data?.header.menu.three} href="#two">{data?.header.menu.three}</a></li>
                        <li><a key={data?.header.menu.four} href="#three">{data?.header.menu.four}</a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
